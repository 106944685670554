import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records, Vendors} from '../../../helpers/Urls';
import UseAbortApiCall from '../../../Common/Hooks/use-abort';
import axios from 'axios';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  addingDetails: false,
  error: '',
  isFetchingCustomerDetails: false,
  getCustomerDetailsData: {},
  isFetchingProductsData: false,
  //pricing
  pricingQueryString: '',
  isFetchingPricingData: false,
  pricingData: {},
  isFetchingPricingLatest: false,
  pricingLatestUploadsData: {},
  isFetchingUploadCategories: false,
  uploadCategoriesData: {},
  isFetchingTonnageTypes: false,
  tonnageTypesData: {},
  //product
  productsData: {},
  productsDataIds: [],
  total: 0,
  productsCategory: [],
  priceUnit: {},
  isProductDataAdding: false,
  isProductDataEditing: false,
  searchFieldData: false,
  save_params: '', // warehouse
  save_search_value: '', // warehouse
  save_params_FULLFILMENT: '',
  save_search_value: '',
};

const ADD_CUSTOMER = 'ADD_CUSTOMER';
const ADD_CUSTOMER_COMPLETED = 'ADD_CUSTOMER_COMPLETED';
const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';

const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
const EDIT_CUSTOMER_COMPLETED = 'EDIT_CUSTOMER_COMPLETED';
const EDIT_CUSTOMER_FAILED = 'EDIT_CUSTOMER_FAILED';

const ADD_CUSTOMER_DETAILS = 'ADD_CUSTOMER_DETAILS';
const ADD_CUSTOMER_DETAILS_COMPLETED = 'ADD_CUSTOMER_DETAILS_COMPLETED';
const ADD_CUSTOMER_DETAILS_FAILED = 'ADD_CUSTOMER_DETAILS_FAILED';

const EDIT_CUSTOMER_DETAILS = 'EDIT_CUSTOMER_DETAILS';
const EDIT_CUSTOMER_DETAILS_COMPLETED = 'EDIT_CUSTOMER_DETAILS_COMPLETED';
const EDIT_CUSTOMER_DETAILS_FAILED = 'EDIT_CUSTOMER_DETAILS_FAILED';

const GENERATE_NEW_OTP = 'GENERATE_NEW_OTP';
const GENERATE_NEW_OTP_COMPLETED = 'GENERATE_NEW_OTP_COMPLETED';
const GENERATE_NEW_OTP_FAILED = 'GENERATE_NEW_OTP_FAILED';

const CUSTOMER_OPERATIONAL_DATA = 'CUSTOMER_OPERATIONAL_DATA';
const CUSTOMER_FINANCIAL_DATA = 'CUSTOMER_FINANCIAL_DATA';

const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
const GET_CUSTOMER_DETAILS_COMPLETED = 'GET_CUSTOMER_DETAILS_COMPLETED';
const GET_CUSTOMER_DETAILS_FAILED = 'GET_CUSTOMER_DETAILS_FAILED';

//pricing

const FETCH_PRICING_DATA = 'FETCH_PRICING_DATA';
const FETCH_PRICING_DATA_COMPLETED = 'FETCH_PRICING_DATA_COMPLETED';
const FETCH_PRICING_DATA_FAILED = 'FETCH_PRICING_DATA_FAILED';

const FETCH_PRICING_LATEST_UPLOADS = 'FETCH_PRICING_LATEST_UPLOADS';
const FETCH_PRICING_LATEST_UPLOADS_COMPLETED =
  'FETCH_PRICING_LATEST_UPLOADS_COMPLETED';
const FETCH_PRICING_LATEST_UPLOADS_FAILED =
  'FETCH_PRICING_LATEST_UPLOADS_FAILED';

const FETCH_UPLOAD_CATEGORIES = 'FETCH_UPLOAD_CATEGORIES';
const FETCH_UPLOAD_CATEGORIES_COMPLETED = 'FETCH_UPLOAD_CATEGORIES_COMPLETED';
const FETCH_UPLOAD_CATEGORIES_FAILED = 'FETCH_UPLOAD_CATEGORIES_FAILED';

const DOWNLOAD_PRICING_REPORT = 'DOWNLOAD_PRICING_REPORT';
const DOWNLOAD_PRICING_REPORT_COMPLETED = 'DOWNLOAD_PRICING_REPORT_COMPLETED';
const DOWNLOAD_PRICING_REPORT_FAILED = 'DOWNLOAD_PRICING_REPORT_FAILED';

const FETCH_TONNAGE_TYPES = 'FETCH_TONNAGE_TYPES';
const FETCH_TONNAGE_TYPES_COMPLETED = 'FETCH_TONNAGE_TYPES_COMPLETED';
const FETCH_TONNAGE_TYPES_FAILED = 'FETCH_TONNAGE_TYPES_FAILED';

export const SWITCH_PRICING_TAB = 'SWITCH_PRICING_TAB';

//product

const FETCH_PRODUCTS_DATA = 'FETCH_PRODUCTS_DATA';
const FETCH_PRODUCTS_DATA_COMPLETED = 'FETCH_PRODUCTS_DATA_COMPLETED';
const FETCH_PRODUCTS_DATA_FAILED = 'FETCH_PRODUCTS_DATA_FAILED';
const RESET_PAGE = 'RESET_PAGE';

const ADD_PRODUCT = 'ADD_PRODUCT';
const ADD_PRODUCT_COMPLETED = 'ADD_PRODUCT_COMPLETED';
const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';

const EDIT_PRODUCT_DATA = 'EDIT_PRODUCT_DATA';
const EDIT_PRODUCT_DATA_COMPLETED = 'EDIT_PRODUCT_DATA_COMPLETED';
const EDIT_PRODUCT_DATA_FAILED = 'EDIT_PRODUCT_DATA_FAILED';

const EMPTY_SEARCH_FIELD_DATA = 'EMPTY_SEARCH_FIELD_DATA';

// warehouse

const GET_WAREHOUSE_DATA = 'GET_WAREHOUSE_DATA';
const GET_WAREHOUSE_DATA_COMPLETE = 'GET_WAREHOUSE_DATA_COMPLETE';
const GET_WAREHOUSE_DATA_FAILED = 'GET_WAREHOUSE_DATA_FAILED';

const SAVE_WAREHOUSE_DATA = 'SAVE_WAREHOUSE_DATA';
const SAVE_WAREHOUSE_DATA_COMPLETE = 'SAVE_WAREHOUSE_DATA_COMPLETE';
const SAVE_WAREHOUSE_DATA_FAILED = 'SAVE_WAREHOUSE_DATA_FAILED';

const OPEN_CLOSE_CUSTOMER_MODAL = 'OPEN_CLOSE_CUSTOMER_MODAL';
const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';

const DOWNLOAD_WAREHOUSE_REPORT = 'DOWNLOAD_WAREHOUSE_REPORT';
const DOWNLOAD_REPORT_WAREHOUSE_COMPLETE = 'DOWNLOAD_REPORT_WAREHOUSE_COMPLETE';
const DOWNLOAD_REPORT_WAREHOUSE_FAILED = 'DOWNLOAD_REPORT_WAREHOUSE_FAILED';

const GET_FULFILLMENT_DATA = 'GET_FULFILLMENT_DATA';
const GET_FULFILLMENT_DATA_COMPLETE = 'GET_FULFILLMENT_DATA_COMPLETE';
const GET_FULFILLMENT_DATA_FAILED = 'GET_FULFILLMENT_DATA_FAILED';

const DELETE_CONFIGURATION = 'DELETE_CONFIGURATION';
const DELETE_CONFIGURATION_COMPLETE = 'DELETE_CONFIGURATION_COMPLETE';
const DELETE_CONFIGURATION_FAILED = 'DELETE_CONFIGURATION_FAILED';

const SAVE_FULFILMENT_DATA = 'SAVE_FULFILMENT_DATA';
const SAVE_FULFILMENT_DATA_COMPLETED = 'SAVE_FULFILMENT_DATA_COMPLETED';
const SAVE_FULFILMENT_DATA_FAILED = 'SAVE_FULFILMENT_DATA_FAILED';

export const switchPricingTab = (current_pricing_tab = null) => {
  return (dispatch) => {
    dispatch({type: SWITCH_PRICING_TAB, payload: {current_pricing_tab}});
  };
};

// FullFilment
export const deleteConfigurations = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_CONFIGURATION});
      response = await doRequest({
        method: REQUEST_TYPE.DELETE,
        url: Records.deleteConfigurations(id),
      });
      const {data, status = true, message = ''} = response || {};

      if (status) {
        dispatch({type: DELETE_CONFIGURATION_COMPLETE, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_CONFIGURATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_CONFIGURATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getFullFilmentData = (page, customerId, extraparams) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_FULFILLMENT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchFullFilmentData(page, customerId, extraparams),
      });
      const {data, status = true} = response || {};
      if (status) {
        dispatch({
          type: GET_FULFILLMENT_DATA_COMPLETE,
          payload: data,
        });
      } else {
        const {message = 'Something Went Wrong'} = response || {};
        dispatch({
          type: GET_FULFILLMENT_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_FULFILLMENT_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addFullFilments = (value, id = '') => {
  return async (dispatch) => {
    let response = {status: true};
    let forAddOrUpdate = id ? 'PUT' : 'POST';
    try {
      dispatch({type: SAVE_FULFILMENT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE[forAddOrUpdate],
        url: Records.saveFullFillmentData(id),
        data: value,
      });
      const {data, status = true} = response || {};
      if (status) {
        if (id) {
          dispatch({
            type: 'UPDATE_FULFILMENT_DATA',
            payload: data,
          });
        } else {
          dispatch({
            type: SAVE_FULFILMENT_DATA_COMPLETED,
            payload: data,
          });
        }
      } else {
        const {message = 'Something Went Wrong'} = response || {};
        dispatch({
          type: SAVE_FULFILMENT_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_FULFILMENT_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const customerOperationalData = (customer_operational_data) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_OPERATIONAL_DATA,
      payload: {customer_operational_data},
    });
  };
};

export const customerFinancialData = (customer_financial_data) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_FINANCIAL_DATA,
      payload: {customer_financial_data},
    });
  };
};

export const emptySearchField = (searchData) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_SEARCH_FIELD_DATA,
      payload: searchData,
    });
  };
};

export const checkAvailability = (data) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.checkAvailabilityURL(),
        data,
      });
    } catch (err) {}
    return response;
  };
};

export const generateNewOtp = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GENERATE_NEW_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.generateNewOtpURL(),
        data: requestData,
      });
      console.log('response --ADD_VENDOR_COMPLETED--', response);
      const {data, status} = response || {};

      if (status) {
        dispatch({type: GENERATE_NEW_OTP_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: GENERATE_NEW_OTP_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: GENERATE_NEW_OTP_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const getCustomerDetails = (customerId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.getCustomerDetailsURL(customerId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_CUSTOMER_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_CUSTOMER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addCustomerDetails = (customerId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addCustomerDetailsURL(customerId),
        data: requestData,
      });
      console.log('response --ADD_CUSTOMER_DETAILS--', response);
      const {data, status} = response || {};

      if (status) {
        dispatch({type: ADD_CUSTOMER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_CUSTOMER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addCustomer = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addCustomerURL(),
        data: requestData,
      });
      console.log('response --ADD_CUSTOMER_COMPLETED--', response);
      const {data, status} = response || {};

      if (status) {
        dispatch({type: ADD_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: ADD_CUSTOMER_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: ADD_CUSTOMER_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editCustomer = (customerId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editCustomerURL(customerId),
        data: requestData,
      });
      console.log('response --EDIT_CUSTOMER--', response);
      const {data, status} = response || {};

      if (status) {
        dispatch({type: EDIT_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: EDIT_CUSTOMER_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: EDIT_CUSTOMER_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editCustomerDetails = (customerId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editCustomerDetailsURL(customerId),
        data: requestData,
      });
      console.log('response --EDIT_CUSTOMER--', response);
      const {data, status} = response || {};

      if (status) {
        dispatch({type: EDIT_CUSTOMER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_CUSTOMER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchPricingData = (
  customerId,
  category,
  page,
  search,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_PRICING_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Records.fetchPricingDataURL(customerId, category, page, search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status) {
        dispatch({
          type: FETCH_PRICING_DATA_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_PRICING_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          error: true,
        };
      }
      dispatch({
        type: FETCH_PRICING_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchPricingLatestUploads = (customerId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_PRICING_LATEST_UPLOADS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchPricingLatestUploadsURL(customerId),
      });
      console.log('response --FETCH_PRICING_LATEST_UPLOADS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_PRICING_LATEST_UPLOADS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_PRICING_LATEST_UPLOADS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRICING_LATEST_UPLOADS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTonnageTypes = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TONNAGE_TYPES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchTonnageTypesURL(),
      });
      console.log('response --FETCH_TONNAGE_TYPES--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TONNAGE_TYPES_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TONNAGE_TYPES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TONNAGE_TYPES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchUploadCategories = (customerId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_UPLOAD_CATEGORIES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchUploadCategoriesURL(customerId),
      });
      console.log('response --FETCH_UPLOAD_CATEGORIES--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_UPLOAD_CATEGORIES_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_UPLOAD_CATEGORIES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_UPLOAD_CATEGORIES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadPricingReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_PRICING_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.downloadPricingReportURL(),
        data: requestData,
      });
      console.log('response --DOWNLOAD_PRICING_REPORT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DOWNLOAD_PRICING_REPORT_COMPLETED,
          payload: data,
        });
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({
          type: DOWNLOAD_PRICING_REPORT_FAILED,
          payload: {error},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_PRICING_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchProductData = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_PRODUCTS_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Records.fetchProductsDataURL(page, search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status) {
        dispatch({type: FETCH_PRODUCTS_DATA_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_PRODUCTS_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          error: true,
        };
      }
      dispatch({
        type: FETCH_PRODUCTS_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addProductData = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_PRODUCT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addProductDataURL(),
        data: requestData,
      });
      const {data, status} = response || {};

      if (status) {
        dispatch({type: ADD_PRODUCT_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: ADD_PRODUCT_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: ADD_PRODUCT_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editProductData = (requestData = {}, productId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_PRODUCT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.PUT,
        url: Records.editProductDataURL(productId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: EDIT_PRODUCT_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_PRODUCT_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_PRODUCT_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadWareHouseReport = (value) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_WAREHOUSE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.downloadWareHouseReport(),
        data: value,
      });
      const {data, status = true} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_REPORT_WAREHOUSE_COMPLETE,
          payload: data,
        });
      } else {
        const {message = 'Something Went Wrong'} = response || {};
        dispatch({
          type: DOWNLOAD_REPORT_WAREHOUSE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REPORT_WAREHOUSE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// comman modal for warehouse and fulfillment

export const openAddCustomerModel = (value) => {
  return async (dispatch) => {
    try {
      dispatch({type: OPEN_CLOSE_CUSTOMER_MODAL, payload: value});
    } catch (err) {}
  };
};

const SAVE_PARAMS = 'SAVE_PARAMS';
// save query params for future use
export const saveFilter = (value = '') => {
  // drawer
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_PARAMS, payload: value});
    } catch (err) {}
    return true;
  };
};

const SAVE_SEARCH_VALUE = 'SAVE_SEARCH_VALUE';

export const saveSearchVl = (value) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_SEARCH_VALUE, payload: value});
    } catch (err) {}
    return true;
  };
};

const WARE_HOUSE_SEARCH_RESET = 'WARE_HOUSE_RESET';

export const resetWarehouseSearch = (flag = false) => {
  return async (dispatch) => {
    try {
      dispatch({type: WARE_HOUSE_SEARCH_RESET, payload: flag});
    } catch (err) {}
    return true;
  };
};

export const addWareHouse = (warehouseId, value) => {
  return async (dispatch) => {
    let response = {status: true};
    let forAddOrUpdate = warehouseId ? 'PUT' : 'POST';
    try {
      dispatch({type: SAVE_WAREHOUSE_DATA});
      response = await doRequest({
        method: REQUEST_TYPE[forAddOrUpdate],
        url: Records.saveWareHouseData(warehouseId),
        data: value,
      });
      const {data, status = true} = response || {};
      if (status) {
        if (warehouseId) {
          dispatch({
            type: UPDATE_WAREHOUSE,
            payload: data.customer_warehouse,
          });
        } else {
          dispatch({
            type: SAVE_WAREHOUSE_DATA_COMPLETE,
            payload: data.customer_warehouse,
          });
        }
      } else {
        const {message = 'Something Went Wrong'} = response || {};
        dispatch({
          type: SAVE_WAREHOUSE_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_WAREHOUSE_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// get Table Data
export const getWareHouseData = (page, customerId, extraparams) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_WAREHOUSE_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchWareHouseData(page, customerId, extraparams),
      });
      const {data, status = true} = response || {};
      if (status) {
        dispatch({
          type: GET_WAREHOUSE_DATA_COMPLETE,
          payload: data,
        });
      } else {
        const {message = 'Something Went Wrong'} = response || {};
        dispatch({
          type: GET_WAREHOUSE_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_WAREHOUSE_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// reducer

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case DELETE_CONFIGURATION:
      return {
        ...state,
        delete_configuration: true,
      };

    case DELETE_CONFIGURATION_COMPLETE:
      let configurations_ = state.fullfilment_data.configurations.filter(
        (item) => item.id != payload.id,
      );

      return {
        ...state,
        delete_configuration: false,
        fullfilment_data: {
          ...state.fullfilment_data,
          configurations: configurations_,
        },
      };

    case DELETE_CONFIGURATION_FAILED:
      return {
        ...state,
        delete_configuration: false,
        delete_configuration_error: payload.error,
      };

    case GET_FULFILLMENT_DATA:
      return {
        ...state,
        start_fetching_fullfilment_data: true,
      };

    case GET_FULFILLMENT_DATA_COMPLETE:
      let fullfilment_data = {
        ...payload,
        from_location_city_list: Object.values(payload.loading_city_data),
        from_location_warehouse_list: Object.values(
          payload.loading_warehouse_data,
        ),
        to_location_city_list: Object.values(payload.unloading_city_data),
        to_location_warehouse_list: Object.values(
          payload.unloading_warehouse_data,
        ),
      };

      return {
        ...state,
        start_fetching_fullfilment_data: false,
        fullfilment_data: fullfilment_data,
      };

    case GET_FULFILLMENT_DATA_FAILED:
      return {
        ...state,
        start_fetching_fullfilment_data: false,
        fullfilment_error: payload.error,
      };

    case SAVE_FULFILMENT_DATA:
      return {
        ...state,
        save_fullfillmentdata: true,
      };

    case SAVE_FULFILMENT_DATA_COMPLETED:
      let updateFullFillmentData = {
        ...state,
        save_fullfillmentdata: false,

        fullfilment_data: {
          ...state.fullfilment_data,
          configurations: [payload, ...state.fullfilment_data.configurations],
        },
      };

      return {
        ...updateFullFillmentData,
      };

    case 'UPDATE_FULFILMENT_DATA':
      let configurations = state.fullfilment_data.configurations.map((item) =>
        item.id === payload.id ? payload : item,
      );
      return {
        ...state,
        save_fullfillmentdata: false,
        fullfilment_data: {
          ...state.fullfilment_data,
          configurations,
        },
      };

    case SAVE_FULFILMENT_DATA_FAILED:
      return {
        ...state,
        save_fullfillmentdata: false,
        fullfilment_error: payload.error,
      };

    // case 'SAVE_SEARCH_VALUE':

    case 'SAVE_PARAMS_FULLFILMENT':
      return {
        ...state,
        save_params_FULLFILMENT: payload,
      };

    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        isFetchingCustomerDetails: true,
        hasError: false,
        error: '',
      };
    case GET_CUSTOMER_DETAILS_COMPLETED:
      return {
        ...state,
        isFetchingCustomerDetails: false,
        hasError: false,
        getCustomerDetailsData: payload.data,
      };
    case GET_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isFetchingCustomerDetails: false,
        hasError: true,
        error: payload.error,
      };
    case ADD_CUSTOMER:
    case EDIT_CUSTOMER:
    case ADD_CUSTOMER_DETAILS:
    case EDIT_CUSTOMER_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case ADD_CUSTOMER_COMPLETED:
    case EDIT_CUSTOMER_COMPLETED:
    case ADD_CUSTOMER_DETAILS_COMPLETED:
    case EDIT_CUSTOMER_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
      };
    case ADD_CUSTOMER_FAILED:
    case EDIT_CUSTOMER_FAILED:
    case ADD_CUSTOMER_DETAILS_FAILED:
    case EDIT_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case CUSTOMER_OPERATIONAL_DATA:
      return {
        customer_operational_data: payload.customer_operational_data,
      };
    case CUSTOMER_FINANCIAL_DATA:
      return {
        customer_financial_data: payload.customer_financial_data,
      };
    // case ADD_CUSTOMER_DETAILS:
    //   return {...state, addingDetails: true};
    // case ADD_CUSTOMER_DETAILS_COMPLETED:
    // case ADD_CUSTOMER_DETAILS_FAILED:
    //   return {...state, addingDetails: false};

    case EMPTY_SEARCH_FIELD_DATA:
      return {
        ...state,
        emptySearchFieldData: payload,
      };
    case FETCH_PRICING_DATA:
      return {
        ...state,
        isFetchingPricingData: true,
        hasError: false,
        error: '',
      };
    case FETCH_PRICING_DATA_COMPLETED:
      return {
        ...state,
        isFetchingPricingData: false,
        pricingData: payload,
        total: payload?.total,
        pricingQueryString: payload?.search,
        hasError: false,
      };
    case FETCH_PRICING_DATA_FAILED:
      return {
        ...state,
        isFetchingPricingData: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_PRICING_LATEST_UPLOADS:
      return {
        ...state,
        isFetchingPricingLatest: true,
        hasError: false,
        error: '',
      };
    case FETCH_PRICING_LATEST_UPLOADS_COMPLETED:
      return {
        ...state,
        isFetchingPricingLatest: false,
        pricingLatestUploadsData: payload,
        hasError: false,
      };
    case FETCH_PRICING_LATEST_UPLOADS_FAILED:
      return {
        ...state,
        isFetchingPricingLatest: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_TONNAGE_TYPES:
      return {
        ...state,
        isFetchingTonnageTypes: true,
        hasError: false,
        error: '',
      };
    case FETCH_TONNAGE_TYPES_COMPLETED:
      return {
        ...state,
        isFetchingTonnageTypes: false,
        tonnageTypesData: payload,
        hasError: false,
      };
    case FETCH_TONNAGE_TYPES_FAILED:
      return {
        ...state,
        isFetchingTonnageTypes: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_UPLOAD_CATEGORIES:
      return {
        ...state,
        isFetchingUploadCategories: true,
        hasError: false,
        error: '',
      };
    case FETCH_UPLOAD_CATEGORIES_COMPLETED:
      return {
        ...state,
        isFetchingUploadCategories: false,
        uploadCategoriesData: payload,
        hasError: false,
      };
    case FETCH_UPLOAD_CATEGORIES_FAILED:
      return {
        ...state,
        isFetchingUploadCategories: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_PRODUCTS_DATA:
      return {
        ...state,
        isFetchingProductsData: true,
        hasError: false,
        error: '',
      };
    case FETCH_PRODUCTS_DATA_COMPLETED:
      return {
        ...state,
        isFetchingProductsData: false,
        productsData: payload?.customer_product,
        productsDataIds: payload?.product_ids,
        total: payload?.total,
        search: payload.query_string || '',
        priceUnit: payload?.price_unit,
        productsCategory: payload?.product_category,
        hasError: false,
      };
    case FETCH_PRODUCTS_DATA_FAILED:
      return {
        ...state,
        isFetchingProductsData: false,
        hasError: true,
        errror: payload.error,
      };

    case WARE_HOUSE_SEARCH_RESET:
      return {
        ...state,
        wareHouseSearchReset: payload,
      };

    case ADD_PRODUCT:
      return {
        ...state,
        isProductDataAdding: true,
        hasError: false,
        error: '',
      };
    case ADD_PRODUCT_COMPLETED:
      return {
        ...state,
        isProductDataAdding: false,
        hasError: false,
      };
    case ADD_PRODUCT_FAILED:
      return {
        ...state,
        isProductDataAdding: false,
        hasError: true,
        errror: payload.error,
      };

    case EDIT_PRODUCT_DATA:
      return {
        ...state,
        isProductDataEditing: true,
        hasError: false,
        error: '',
      };
    case EDIT_PRODUCT_DATA_COMPLETED:
      return {
        ...state,
        isProductDataEditing: false,
        hasError: false,
      };
    case EDIT_PRODUCT_DATA_FAILED:
      return {
        ...state,
        isProductDataEditing: false,
        hasError: true,
        errror: payload.error,
      };

    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };

    // warehouse reducer drawer

    case SAVE_PARAMS:
      return {
        ...state,
        save_params: payload,
      };

    case SAVE_SEARCH_VALUE:
      // input field search
      return {
        ...state,
        save_search_value: payload,
      };

    // comman modal for warehouse and fullfilment
    case OPEN_CLOSE_CUSTOMER_MODAL:
      return {
        ...state,
        openModal: payload.openModal,
        editModal: payload?.editModal || {},
      };

    case GET_WAREHOUSE_DATA:
      return {
        ...state,
        start_fetching_warehouse_data: true,
      };

    case SAVE_WAREHOUSE_DATA:
      return {
        ...state,
        start_saving_warehouse_data: true,
        warehouse_save_error: '',
      };

    case SAVE_WAREHOUSE_DATA_COMPLETE:
      let updateData = {
        ...state,
        start_saving_warehouse_data: false,
        warehouse_data: {
          ...state.warehouse_data,
          warehouse_record: [payload, ...state.warehouse_data.warehouse_record],
        },
      };

      return {
        ...updateData,
      };

    case UPDATE_WAREHOUSE:
      let warehouse_record = state.warehouse_data.warehouse_record.map((item) =>
        item.id === payload.id ? payload : item,
      );
      return {
        ...state,
        start_saving_warehouse_data: false,
        warehouse_data: {
          ...state.warehouse_data,
          warehouse_record,
        },
      };

    case SAVE_WAREHOUSE_DATA_FAILED:
      return {
        ...state,
        start_saving_warehouse_data: false,
        warehouse_save_error: payload.error,
      };

    case GET_WAREHOUSE_DATA_COMPLETE:
      return {
        ...state,
        start_fetching_warehouse_data: false,
        warehouse_data: payload,
      };
    case GET_WAREHOUSE_DATA_FAILED:
      return {
        ...state,
        start_fetching_warehouse_data: false,
        warehouse_error: payload.error,
      };

    case DOWNLOAD_WAREHOUSE_REPORT:
      return {
        ...state,
        reportloading: true,
      };

    case DOWNLOAD_REPORT_WAREHOUSE_COMPLETE: {
      return {
        ...state,
        reportloading: false,
      };
    }

    case DOWNLOAD_REPORT_WAREHOUSE_FAILED:
      return {
        ...state,
        reportloading: false,
      };
    case SWITCH_PRICING_TAB:
      return {
        current_pricing_tab: payload.current_pricing_tab,
      };
    default:
      return state;
  }
};
